/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type SortReviewByParameter = typeof SortReviewByParameter[keyof typeof SortReviewByParameter];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortReviewByParameter = {
  CREATED_AT: 'CREATED_AT',
  UPDATED_AT: 'UPDATED_AT',
} as const;
