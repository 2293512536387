'use client';

import { DatePicker } from '@/components/DatePicker';
import {
  EditPostAssetItemsDialog,
  PostAssetItemDialog,
  PostAssetItemView,
} from '@/components/File';
import { FormItem } from '@/components/Form';
import { Modal } from '@/components/Modal';
import { Select, SelectTime } from '@/components/Select';
import {
  PostGbp,
  UpsertGbpPostBody,
  UpsertGbpPostBodyActionType,
} from '@/lib/api/schema';
import {
  GbpActionTypeOption,
  GbpPostTypeOption,
  PostMedia,
} from '@/types/post';
import { HelpText } from '@/types/store';
import { Flex, Input, Switch, Text, Textarea } from '@chakra-ui/react';
import { formatDate } from 'date-fns';
import { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

const topicTypeOptions: GbpPostTypeOption[] = [
  {
    value: 'STANDARD',
    label: '最新情報',
  },
  {
    value: 'EVENT',
    label: 'イベント',
  },
  {
    value: 'OFFER',
    label: '特典',
  },
];

const actionTypeOptions: GbpActionTypeOption[] = [
  {
    value: '',
    label: 'なし',
  },
  {
    value: 'BOOK',
    label: '予約',
  },
  {
    value: 'ORDER',
    label: 'オンライン注文',
  },
  {
    value: 'SHOP',
    label: '購入',
  },
  {
    value: 'LEARN_MORE',
    label: '詳細',
  },
  {
    value: 'SIGN_UP',
    label: '登録',
  },
  {
    value: 'CALL',
    label: '今すぐ電話',
  },
];

type GooglePostFormProps = {
  companyId: string | null;
  initGbpData: PostGbp | null;
  setHelpText: (val: HelpText | undefined) => void;
  isEditForm?: boolean;
};

export function GooglePostForm({
  companyId,
  initGbpData,
  setHelpText,
  isEditForm = false,
}: GooglePostFormProps) {
  const [startDate, setStartDate] = useState<string>(
    initGbpData?.event?.startDateTime
      ? formatDate(initGbpData?.event?.startDateTime, 'yyyy-MM-dd')
      : '',
  );
  const [startTime, setStartTime] = useState<string>(
    initGbpData?.event?.startDateTime
      ? formatDate(initGbpData?.event?.startDateTime, 'HH:mm')
      : '',
  );
  const [endDate, setEndDate] = useState<string>(
    initGbpData?.event?.endDateTime
      ? formatDate(initGbpData?.event?.endDateTime, 'yyyy-MM-dd')
      : '',
  );
  const [endTime, setEndTime] = useState<string>(
    initGbpData?.event?.endDateTime
      ? formatDate(initGbpData?.event?.endDateTime, 'HH:mm')
      : '',
  );
  const [imageItem, setImageItem] = useState<PostMedia | null>(
    initGbpData?.mediaUrl && isEditForm
      ? { type: 'image', url: initGbpData?.mediaUrl }
      : null,
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const {
    control,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<UpsertGbpPostBody>();

  const topicType = useWatch({
    control,
    name: 'topicType',
  });
  const action = useWatch({
    control,
    name: 'action',
  });
  const coupon = useWatch({
    control,
    name: 'coupon',
  });

  return (
    <>
      <FormItem
        label="投稿タイプ"
        isRequired
        onMouseEnter={() => setHelpText(undefined)}
        onMouseLeave={() => setHelpText(undefined)}
        maxW={'252px'}
        error={errors.topicType}
      >
        <Controller
          control={control}
          name="topicType"
          render={({ field: { value, onChange } }) => (
            <Select
              placeholder="選択してください"
              options={topicTypeOptions}
              value={topicTypeOptions.find((opt) => opt.value === value)}
              onChange={(opt) => {
                if (!coupon && opt.value === 'OFFER') {
                  setValue(
                    'coupon',
                    {
                      applyPostLink: false,
                      code: null,
                      url: null,
                      term: null,
                    },
                    { shouldValidate: true },
                  );
                }
                onChange(opt.value);
              }}
              isDisabled={isEditForm}
            />
          )}
        />
      </FormItem>
      {(topicType === 'EVENT' || topicType === 'OFFER') && (
        <>
          <FormItem
            label="タイトル"
            isRequired
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            maxW={'252px'}
            error={errors.event?.title}
          >
            <Input
              placeholder="入力してください"
              {...register('event.title')}
            />
          </FormItem>
          <FormItem
            label="期間"
            isRequired
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            error={errors.event?.startDateTime || errors.event?.endDateTime}
          >
            <Flex gap={3} alignItems="center">
              <Controller
                control={control}
                name="event.startDateTime"
                render={({ field: { onChange } }) => (
                  <Flex gap={3} alignItems="center">
                    <DatePicker
                      monthsShown={2}
                      minDate={new Date()}
                      selected={startDate ? new Date(startDate) : new Date()}
                      value={startDate.replace(/-/g, '.')}
                      onChange={(v) => {
                        if (v) {
                          setStartDate(formatDate(v, 'yyyy-MM-dd'));
                          if (startTime) {
                            onChange(
                              formatDate(v, 'yyyy-MM-dd') + ' ' + startTime,
                            );
                          }
                        }
                      }}
                    />
                    <SelectTime
                      placeholder="--:--"
                      value={startTime}
                      onChange={(v) => {
                        if (v) {
                          setStartTime(v);
                          if (startDate) {
                            onChange(startDate + ' ' + v);
                          }
                        }
                      }}
                    />
                  </Flex>
                )}
              />
              -
              <Controller
                control={control}
                name="event.endDateTime"
                render={({ field: { onChange } }) => (
                  <Flex gap={3} alignItems="center">
                    <DatePicker
                      monthsShown={2}
                      minDate={new Date()}
                      selected={endDate ? new Date(endDate) : new Date()}
                      value={endDate.replace(/-/g, '.')}
                      onChange={(v) => {
                        if (v) {
                          setEndDate(formatDate(v, 'yyyy-MM-dd'));
                          if (endTime) {
                            onChange(
                              formatDate(v, 'yyyy-MM-dd') + ' ' + endTime,
                            );
                            trigger('event.startDateTime');
                          }
                        }
                      }}
                    />
                    <SelectTime
                      placeholder="--:--"
                      value={endTime}
                      onChange={(v) => {
                        if (v) {
                          setEndTime(v);
                          if (endDate) {
                            onChange(endDate + ' ' + v);
                            trigger('event.startDateTime');
                          }
                        }
                      }}
                    />
                  </Flex>
                )}
              />
            </Flex>
          </FormItem>
        </>
      )}
      <FormItem
        label="写真"
        onMouseEnter={() => setHelpText(undefined)}
        onMouseLeave={() => setHelpText(undefined)}
        error={errors.mediaUrl}
      >
        <Flex gap={2} wrap="wrap" pb={2}>
          <EditPostAssetItemsDialog
            companyId={companyId}
            maxItem={1}
            onSubmit={(values) => {
              if (values.length > 0 && values[0].originalUrl) {
                setImageItem({ type: 'image', url: values[0].displayUrl });
                setValue('mediaUrl', values[0].originalUrl, {
                  shouldValidate: true,
                });
              }
            }}
            isPhotoOnly
          />
          {imageItem && (
            <>
              <PostAssetItemView
                assetItem={imageItem}
                onClick={() => setIsViewDialogOpen(true)}
                onDeleteClick={() => setIsDeleteDialogOpen(true)}
              />
              <PostAssetItemDialog
                isOpen={isViewDialogOpen}
                url={imageItem.url}
                onClose={() => setIsViewDialogOpen(false)}
                onNegativeClick={() => setIsDeleteDialogOpen(true)}
                isPhotoOnly
              />
            </>
          )}
        </Flex>
      </FormItem>
      <Controller
        control={control}
        name="body"
        render={({ field: { value, onChange } }) => (
          <FormItem
            label="本文"
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            rightElm={
              <Text
                fontSize="sm"
                color="gray.300"
              >{`${value?.length || 0}/1500`}</Text>
            }
            error={errors.body}
          >
            <Textarea
              placeholder="入力してください"
              resize="vertical"
              rows={10}
              value={value || ''}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </FormItem>
        )}
      />
      {(topicType === 'EVENT' || topicType === 'STANDARD') && (
        <>
          <FormItem
            label="ボタン"
            isRequired
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            maxW={'252px'}
          >
            <Select
              placeholder="選択してください"
              options={actionTypeOptions}
              value={
                action === null
                  ? actionTypeOptions.find((opt) => opt.value === '')
                  : actionTypeOptions.find((opt) => opt.value === action.type)
              }
              onChange={(opt) => {
                if (opt.value) {
                  setValue(
                    'action',
                    {
                      type: opt.value as UpsertGbpPostBodyActionType,
                      url: action?.url || null,
                      applyPostLink: action?.applyPostLink || false,
                    },
                    { shouldValidate: true },
                  );
                } else {
                  setValue('action', null, { shouldValidate: true });
                }
              }}
            />
          </FormItem>
          {action && action.type && (
            <FormItem
              label="ボタンのリンク"
              isRequired
              onMouseEnter={() => setHelpText(undefined)}
              onMouseLeave={() => setHelpText(undefined)}
              rightElm={
                action.type !== 'CALL' ? (
                  <Controller
                    control={control}
                    name="action.applyPostLink"
                    render={({ field: { value, onChange } }) => (
                      <Flex gap={2} alignItems="center">
                        <Switch
                          isChecked={value ?? false}
                          onChange={(value) => {
                            onChange(value);
                            trigger('action.url');
                          }}
                        />
                        <Text>店舗マスタの投稿用リンクを適用</Text>
                      </Flex>
                    )}
                  />
                ) : null
              }
              error={errors.action?.url}
            >
              {action.type === 'CALL' ? (
                <Text>店舗マスタの「電話番号」が適用されます</Text>
              ) : (
                <Input
                  type="url"
                  placeholder="https://"
                  {...register('action.url')}
                  isDisabled={action.applyPostLink}
                />
              )}
            </FormItem>
          )}
        </>
      )}
      {topicType === 'OFFER' && (
        <>
          <FormItem
            label="クーポンコード"
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            error={errors.coupon?.code}
          >
            <Input
              placeholder="入力してください"
              {...register('coupon.code')}
            />
          </FormItem>
          <FormItem
            label="クーポンURL"
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            rightElm={
              <Controller
                control={control}
                name="coupon.applyPostLink"
                render={({ field: { value, onChange } }) => (
                  <Flex gap={2} alignItems="center">
                    <Switch
                      isChecked={value ?? false}
                      onChange={(value) => {
                        onChange(value);
                        trigger('coupon.url');
                      }}
                    />
                    <Text>店舗マスタの投稿用リンクを適用</Text>
                  </Flex>
                )}
              />
            }
            error={errors.coupon?.url}
          >
            <Input
              type="url"
              placeholder="入力してください"
              {...register('coupon.url')}
              isDisabled={coupon?.applyPostLink}
            />
          </FormItem>
          <FormItem
            label="クーポン利用規約"
            onMouseEnter={() => setHelpText(undefined)}
            onMouseLeave={() => setHelpText(undefined)}
            error={errors.coupon?.term}
          >
            <Input
              placeholder="入力してください"
              {...register('coupon.term')}
            />
          </FormItem>
        </>
      )}

      <Modal
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        title="写真を削除"
        message="写真を完全に削除しますか？この操作は元に戻せません。"
        buttons={{
          secondary: { onClick: () => setIsDeleteDialogOpen(false) },
          danger: {
            onClick: () => {
              setImageItem(null);
              setValue('mediaUrl', null, {
                shouldValidate: true,
              });
              setIsDeleteDialogOpen(false);
              setIsViewDialogOpen(false);
            },
          },
        }}
      />
    </>
  );
}
