/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type SortMediaFileByParameter = typeof SortMediaFileByParameter[keyof typeof SortMediaFileByParameter];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortMediaFileByParameter = {
  CREATED_AT: 'CREATED_AT',
} as const;
