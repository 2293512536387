/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * ステータス
 */
export type MediaFileStatus = typeof MediaFileStatus[keyof typeof MediaFileStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaFileStatus = {
  SYNCHRONIZED: 'SYNCHRONIZED',
  UNSYNCHRONIZED: 'UNSYNCHRONIZED',
  SYNCHRONIZING: 'SYNCHRONIZING',
} as const;
