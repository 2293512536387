/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 投稿プロセスステータス
 */
export type PostGroupPostProcessStatus = typeof PostGroupPostProcessStatus[keyof typeof PostGroupPostProcessStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostGroupPostProcessStatus = {
  UNPROCESSED: 'UNPROCESSED',
  ENQUEUED: 'ENQUEUED',
  REVIEWING: 'REVIEWING',
  PROCESSED: 'PROCESSED',
} as const;
