import { atom } from 'jotai';
import { abilityAtom } from '@/lib/atoms/casl';

export const adminAbilitiesAtom = atom((get) => {
  const ability = get(abilityAtom);

  return {
    // アドミンユーザー
    isAdmin: ability?.can('manage', 'all') || false,
  };
});

export const storeAbilitiesAtom = atom((get) => {
  const ability = get(abilityAtom);

  return {
    // 店舗情報 編集可
    isStoreInfoEdit: ability?.can('store.information', 'edit') || false,
    // 店舗情報 削除可
    isStoreInfoDelete: ability?.can('store.information', 'delete') || false,
    // 店舗情報 紐づけ可
    isStoreInfoLink: ability?.can('store.information', 'link') || false,
    // 店舗情報 GBP紐づけ可
    isStoreInfoGBPLink:
      ability?.can('store.information.google', 'link') || false,
    // 店舗情報 Apple紐づけ可
    isStoreInfoAppleLink:
      ability?.can('store.information.apple', 'link') || false,
    // 店舗情報 Yahoo!紐づけ可
    isStoreInfoYahooLink:
      ability?.can('store.information.yahoo', 'link') || false,
    // 店舗情報 Facebook紐づけ可
    isStoreInfoFacebookLink:
      ability?.can('store.information.facebook', 'link') || false,
    // 店舗情報 Instagram紐づけ可
    isStoreInfoInstagramLink:
      ability?.can('store.information.instagram', 'link') || false,

    // 店舗一覧 ユーザーからの提案
    isStoreListTamperProtection:
      ability?.can('store.list.tamper_protection', 'enable') || false,
    // 店舗一覧 一括ダウンロード可
    isStoreListBulkDownload:
      ability?.can('store.list.bulk', 'download') || false,
    // 店舗一覧 一括紐づけ可
    isStoreListBulkLink: ability?.can('store.list.bulk', 'link') || false,
    // 店舗一覧 一括編集可
    isStoreListBulkEdit: ability?.can('store.list.bulk', 'edit') || false,
    // 店舗一覧 一括新規作成可
    isStoreListBulkCreate: ability?.can('store.list.bulk', 'create') || false,
  };
});

export const reviewAbilitiesAtom = atom((get) => {
  const ability = get(abilityAtom);

  return {
    // クチコミ一覧 作成
    isReviewListCreate: ability?.can('review.list', 'create') || false,
    // クチコミ一覧 編集
    isReviewListEdit: ability?.can('review.list', 'edit') || false,
    // クチコミ一覧　削除
    isReviewListDelete: ability?.can('review.list', 'delete') || false,
    // クチコミ一覧 承認
    isReviewListApprove: ability?.can('review.list', 'approve') || false,
    // クチコミ一覧 却下
    isReviewListReject: ability?.can('review.list', 'reject') || false,
    // クチコミ一覧 公開中
    isReviewListPublishedTab:
      ability?.can('review.list.published', 'read') || false,
    // クチコミ一覧 申請中
    isReviewListPendingTab:
      ability?.can('review.list.pending_application', 'read') || false,
    // クチコミ一覧 承認待ち
    isReviewListApprovalTab:
      ability?.can('review.list.pending_approval', 'read') || false,
    // クチコミ一覧 削除済み
    isReviewListDeletedTab:
      ability?.can('review.list.deleted', 'read') || false,
  };
});

export const postAbilitiesAtom = atom((get) => {
  const ability = get(abilityAtom);

  return {
    // 投稿一覧 新規作成
    isPostListCreate: ability?.can('post.list', 'create') || false,
    // 投稿一覧 編集
    isPostListEdit: ability?.can('post.list', 'edit') || false,
    // 投稿一覧 削除
    isPostListDelete: ability?.can('post.list', 'delete') || false,
    // 投稿一覧 承認
    isPostListApprove: ability?.can('post.list', 'approve') || false,
    // 投稿一覧 却下
    isPostListReject: ability?.can('post.list', 'reject') || false,
    // 投稿一覧 公開中
    isPostListPublished: ability?.can('post.list.published', 'read') || false,
    // 投稿一覧 予約中
    isPostListScheduled: ability?.can('post.list.scheduled', 'read') || false,
    // 投稿一覧 申請中
    isPostListPendingApplication:
      ability?.can('post.list.pending_application', 'read') || false,
    // 投稿一覧 承認待ち
    isPostListPendingApproval:
      ability?.can('post.list.pending_approval', 'read') || false,
    // 投稿一覧 投稿エラー
    isPostListError: ability?.can('post.list.error', 'read') || false,
    // 投稿一覧 Instagram自動投稿
    isPostListInstagram:
      ability?.can(
        'post.list.instagram_posts_multi_platform_posting',
        'enable',
      ) || false,
    // 新規投稿作成
    isPostCreation: ability?.can('post.creation', 'read') || false,
    // 新規投稿作成 承認申請
    isPostCreationApproval: ability?.can('post.creation', 'create') || false,
    // 新規投稿作成 投稿
    isPostCreationPublish: ability?.can('post.creation', 'publish') || false,
    // 投稿編集
    isPostEditing: ability?.can('post.editing', 'read') || false,
    // 投稿編集 承認申請
    isPostEditingApproval: ability?.can('post.editing', 'edit') || false,
    // 投稿編集 投稿
    isPostEditingPublish: ability?.can('post.editing', 'publish') || false,
    // Instagram投稿他媒体連携
    isPostInstagramMultiPlatform:
      ability?.can('post.instagram_posts_multi_platform_posting', 'read') ||
      false,
    // Instagram投稿他媒体連携 編集
    isPostInstagramMultiPlatformEdit:
      ability?.can('post.instagram_posts_multi_platform_posting', 'edit') ||
      false,
    // Instagram投稿他媒体連携 連携
    isPostInstagramMultiPlatformLink:
      ability?.can('post.instagram_posts_multi_platform_posting', 'link') ||
      false,
  };
});
