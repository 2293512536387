import { FormItem } from '@/components/Form';
import { Modal } from '@/components/Modal';
import { EditRankingKeywordsBody, RankingKeywords } from '@/lib/api/schema';
import {
  upsertRankingKeywordsBody,
  upsertRankingKeywordsBodyKeywordsMax,
} from '@/lib/zod/ranking';
import { Input, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const keywordArray = Array.from(
  { length: upsertRankingKeywordsBodyKeywordsMax },
  () => '',
);

type EditRankingKeywordDialogProps = {
  isOpen: boolean;
  isLoading?: boolean;
  keywords?: RankingKeywords;
  onClose: () => void;
  onSubmit: (input: EditRankingKeywordsBody) => void;
};

export function EditRankingKeywordDialog({
  isOpen,
  isLoading,
  keywords,
  onClose,
  onSubmit,
}: EditRankingKeywordDialogProps) {
  const [disabled, setDisabled] = useState(true);

  const { handleSubmit, register, watch, setValue } =
    useForm<EditRankingKeywordsBody>({
      defaultValues: {
        keywords: keywords?.keywords,
      },
      shouldFocusError: false,
      mode: 'onChange',
      criteriaMode: 'all',
      resolver: zodResolver(upsertRankingKeywordsBody),
    });

  /**
   * 送信ボタンを押した際のDispatch前のinput内容整形処理
   * - 空文字を削除
   * - 前後の空白をtrim
   *
   * @param input
   */
  const preSubmit = (input: EditRankingKeywordsBody) => {
    onSubmit({
      keywords:
        input.keywords
          ?.filter((value) => !!value)
          .map((value) => value.trim()) || null,
    });
  };

  // 入力の変更を監視
  keywordArray.forEach((_, index) => watch(`keywords.${index}`));

  // 初期値から変更がない場合送信ボタンを非活性にする
  // FIXME: formState.isDirtyが期待通り動かないので独自実装
  useEffect(() => {
    const subscription = watch((value) => {
      const defaultValues = [...(keywords?.keywords || keywordArray)]
        .filter((value) => !!value)
        .sort();
      const inputValues = [...(value.keywords || keywordArray)]
        .filter((value) => !!value)
        .sort();

      setDisabled(defaultValues.toString() === inputValues.toString());
    });
    return () => subscription.unsubscribe();
  }, [keywords, watch]);

  // 初期値の設定が変更された際に入力内容を変更する
  useEffect(() => {
    keywords?.keywords?.forEach((value, index) => {
      setValue(`keywords.${index}`, value);
    });
  }, [keywords, setValue]);

  return (
    <Modal
      title="キーワード設定"
      isOpen={isOpen}
      size="lg"
      variant="fixedHeight"
      buttons={{
        primary: {
          label: '変更',
          isDisabled: disabled,
          isLoading: isLoading,
          onClick: () =>
            handleSubmit(preSubmit, (e) => {
              setDisabled(true);
              console.error(e);
            })(),
        },
        secondary: { onClick: onClose },
      }}
      modalBody={{
        height: '300px',
        minHeight: '300px',
        pb: '18px !important',
      }}
      onClose={onClose}
      showCloseButton
    >
      <Text mb="8px">
        キーワードを変更すると、新しいキーワードでの計測結果は24時間以内に適用されます。
        <br />
        なお、変更前のデータは参照できなくなります。
        <br />
        キーワードを複数入力するには、キーワード間に半角スペースまたは全角スペースを入力してください。
        <br />
        （例：東京 居酒屋 おすすめ）
      </Text>

      {keywordArray.map((_, index) => (
        <FormItem key={index} mt={3}>
          <Input
            type="text"
            placeholder="例：東京 居酒屋 おすすめ"
            {...register(`keywords.${index}`)}
          />
        </FormItem>
      ))}
    </Modal>
  );
}
