/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type GetPostsHasError = typeof GetPostsHasError[keyof typeof GetPostsHasError];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPostsHasError = {
  YES: 'YES',
  NO: 'NO',
} as const;
