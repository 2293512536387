import i18next from 'i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import translation from 'zod-i18n-map/locales/ja/zod.json';

// lng and resources key depend on your locale.
i18next.init({
  lng: 'ja',
  resources: {
    ja: {
      zod: {
        ...translation,
        errors: {
          ...translation.errors,
          too_small: {
            ...translation.errors.too_small,
            string: {
              ...translation.errors.too_small.string,
              inclusive: '{{minimum}}文字以上で入力してください。',
            },
          },
          too_big: {
            ...translation.errors.too_big,
            string: {
              ...translation.errors.too_big.string,
              inclusive: '{{maximum}}文字以内で入力してください。',
            },
          },
        },
      },
    },
  },
});
z.setErrorMap(zodI18nMap);

// export configured zod instance
export { z };
