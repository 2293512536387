/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * アクションタイプ
 */
export type UpsertGbpPostBodyActionType = typeof UpsertGbpPostBodyActionType[keyof typeof UpsertGbpPostBodyActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpsertGbpPostBodyActionType = {
  BOOK: 'BOOK',
  ORDER: 'ORDER',
  SHOP: 'SHOP',
  LEARN_MORE: 'LEARN_MORE',
  SIGN_UP: 'SIGN_UP',
  CALL: 'CALL',
} as const;
