/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * メディアファイルの種別
 */
export type ApplyMediaFilesBodyMediaType = typeof ApplyMediaFilesBodyMediaType[keyof typeof ApplyMediaFilesBodyMediaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplyMediaFilesBodyMediaType = {
  GBP: 'GBP',
  YAHOO: 'YAHOO',
  APPLE: 'APPLE',
} as const;
