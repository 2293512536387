import { Icon } from '@/components/Icon';
import { SearchBox } from '@/components/Input';
import { getBrands } from '@/lib/api/client/brands';
import { getGroups } from '@/lib/api/client/group';
import { getStores } from '@/lib/api/client/store';
import { apiErrorRoutingHandler } from '@/lib/api/mutator/custom-instance';
import { Brand, GeneralError, Group, StoreInfo } from '@/lib/api/schema';
import { PostGroupCondition } from '@/types/post';
import {
  Box,
  Button,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useRouter } from 'next/navigation';
import { useCallback, useState } from 'react';

type PostStoreGroupDialogProps = {
  companyId: string | null;
  storeIds: string[];
  initStoreName?: string;
  isReadonly?: boolean;
  onSubmit: (storeIds: string[]) => void;
};

export function PostStoreGroupDialog({
  companyId,
  storeIds,
  initStoreName,
  isReadonly = false,
  onSubmit,
}: PostStoreGroupDialogProps) {
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [allStores, setAllStores] = useState<StoreInfo[]>([]);
  const [filteredStores, setFilteredStores] = useState<StoreInfo[]>([]);
  const [selectedStoreIds, setSelectedStoreIds] = useState<string[]>(storeIds);
  const [storeCanSearch, setStoreCanSearch] = useState<boolean>(false);
  const [storeWord, setStoreWord] = useState<string>('');
  const [isNoGroup, setIsNoGroup] = useState<boolean>(false);
  const [filteredGroups, setFilteredGroups] = useState<Group[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [selectedGroupCondition, setSelectedGroupCondition] =
    useState<PostGroupCondition>('and');
  const [groupCanSearch, setGroupCanSearch] = useState<boolean>(false);
  const [groupWord, setGroupWord] = useState<string>('');
  const [filteredBrands, setFilteredBrands] = useState<Brand[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [brandCanSearch, setBrandCanSearch] = useState<boolean>(false);
  const [brandWord, setBrandWord] = useState<string>('');
  const [isComposing, setIsComposing] = useState<boolean>(false);

  const [storeName, setStoreName] = useState<string | null>(
    initStoreName || null,
  );
  const [groups, setGroups] = useState<Group[]>([]);
  const [groupCondition, setGroupCondition] =
    useState<PostGroupCondition>('and');
  const [brand, setBrand] = useState<Brand | null>(null);

  const handleStoreSearch = useCallback(
    async (word: string) => {
      if (!companyId) return;
      const params =
        word === ''
          ? { companyIds: [companyId] }
          : { companyIds: [companyId], keywords: word.split(/[\s　]/) };
      try {
        const res = await getStores(params);
        if (word === '') {
          setAllStores(res.items || []);
        }
        setFilteredStores(res.items || []);
      } catch (error) {
        apiErrorRoutingHandler(router, error as GeneralError);
      } finally {
        setStoreCanSearch(false);
      }
    },
    [companyId, router],
  );

  const handleGroupSearch = useCallback(
    async (word: string) => {
      if (!companyId) return;
      const params =
        word === ''
          ? { companyIds: [companyId] }
          : { companyIds: [companyId], names: word.split(/[\s　]/) };
      try {
        const res = await getGroups(params);
        if (word === '' && !res.items) {
          setIsNoGroup(true);
        }
        setFilteredGroups(res.items || []);
      } catch (error) {
        apiErrorRoutingHandler(router, error as GeneralError);
      } finally {
        setGroupCanSearch(false);
      }
    },
    [companyId, router],
  );

  const handleBrandSearch = useCallback(
    async (word: string) => {
      if (!companyId) return;
      const params =
        word === ''
          ? { companyIds: [companyId] }
          : { companyIds: [companyId], names: word.split(/[\s　]/) };
      try {
        const res = await getBrands(params);
        setFilteredBrands(res.items || []);
      } catch (error) {
        apiErrorRoutingHandler(router, error as GeneralError);
      } finally {
        setBrandCanSearch(false);
      }
    },
    [companyId, router],
  );

  const handleGroupChange = useCallback(
    (values: (string | number)[]) => {
      // 検索結果外で選択されているグループはそのまま残す
      const retainedGroups = selectedGroups.filter(
        (g) => !filteredGroups.some((fg) => fg.id === g.id),
      );
      const newSelectedGroups = filteredGroups.filter((g) =>
        values.includes(g.id),
      );
      const newGroups = [...retainedGroups, ...newSelectedGroups];
      setSelectedGroups(newGroups);
      setSelectedBrand(null);
      if (selectedGroupCondition === 'and') {
        const ids =
          newGroups.length > 0
            ? newGroups.reduce((commonIds, item) => {
                return commonIds.filter((id) => item.storeIds.includes(id));
              }, newGroups[0].storeIds)
            : [];
        setSelectedStoreIds([...ids]);
      } else {
        const ids = newGroups.flatMap((group) => group.storeIds);
        setSelectedStoreIds([...new Set(ids)]);
      }
    },
    [filteredGroups, selectedGroupCondition, selectedGroups],
  );

  const handleBrandChange = useCallback(
    async (value: string) => {
      const newBrand = filteredBrands.find((b) => b.id === value);
      setSelectedBrand(newBrand || null);
      setSelectedGroups([]);
      const ids = newBrand?.storeIds || [];
      setSelectedStoreIds([...ids]);
    },
    [filteredBrands],
  );

  const handleOpen = useCallback(() => {
    handleStoreSearch('');
    handleGroupSearch('');
    handleBrandSearch('');
    if (storeIds.length === 0) {
      setSelectedStoreIds([]);
      setSelectedGroups([]);
      setSelectedGroupCondition('and');
      setSelectedBrand(null);
    }
    onOpen();
  }, [
    handleBrandSearch,
    handleGroupSearch,
    handleStoreSearch,
    onOpen,
    storeIds,
  ]);

  const handleClose = useCallback(() => {
    setIsNoGroup(false);
    setStoreWord('');
    setGroupWord('');
    setBrandWord('');
    setSelectedStoreIds(storeIds);
    setSelectedGroups(groups);
    setSelectedGroupCondition(groupCondition);
    setSelectedBrand(brand);
    onClose();
  }, [onClose, brand, groups, groupCondition, storeIds]);

  const handleSubmit = useCallback(() => {
    if (selectedStoreIds.length === 1) {
      const store = allStores.find((s) => s.storeId === selectedStoreIds[0]);
      setStoreName(store?.name || null);
    }
    setGroups(selectedGroups);
    setGroupCondition(selectedGroupCondition);
    setBrand(selectedBrand);
    onSubmit(selectedStoreIds);
    onClose();
  }, [
    allStores,
    onClose,
    onSubmit,
    selectedBrand,
    selectedGroups,
    selectedGroupCondition,
    selectedStoreIds,
  ]);

  return (
    <>
      <Flex gap={4} alignItems="center">
        <Button
          variant="tertiary"
          fontWeight="normal"
          onClick={handleOpen}
          isDisabled={isReadonly || !companyId}
        >
          店舗/グループ選択
        </Button>
        <Text>
          {companyId
            ? storeName && storeIds.length === 1
              ? `${storeName}を選択中`
              : groups.length > 0 && storeIds.length > 0
                ? `${groups.map((g) => g.name).join('、')}を選択中`
                : `${storeIds.length}店舗選択中`
            : '会社を選択してください'}
        </Text>
      </Flex>

      <Modal
        variant="fixedHeight"
        size="lg"
        isOpen={isOpen}
        onClose={handleClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>投稿する店舗</ModalHeader>
          <ModalCloseButton />
          <ModalBody py={0}>
            <Tabs w="full" defaultIndex={2}>
              <TabList>
                <Tab>
                  <Flex alignItems="center">ブランド</Flex>
                </Tab>
                <Tab>
                  <Flex alignItems="center">グループ</Flex>
                </Tab>
                <Tab>
                  <Flex alignItems="center">店舗</Flex>
                </Tab>
              </TabList>
              <TabIndicator />
              <TabPanels>
                <TabPanel pt={3} pb={0}>
                  <Stack gap={3}>
                    <Box position="relative" top="0">
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          <Icon name="search" color="blue.500" />
                        </InputLeftElement>
                        <Input
                          placeholder="ブランド名で検索"
                          pl={10}
                          onInput={() => setBrandCanSearch(true)}
                          onChange={(e) => {
                            setBrandWord(e.target.value);
                            if (e.target.value.length === 0) {
                              handleBrandSearch('');
                            }
                          }}
                          onCompositionStart={() => setIsComposing(true)}
                          onCompositionEnd={() => setIsComposing(false)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' && !isComposing)
                              handleBrandSearch(brandWord);
                          }}
                        />
                      </InputGroup>
                      {brandCanSearch && brandWord.length > 0 && (
                        <SearchBox
                          word={brandWord}
                          onClickSearchBox={handleBrandSearch}
                        />
                      )}
                    </Box>
                    {filteredBrands.length === 0 ? (
                      <Center textAlign="center" mt={8}>
                        <Stack align="center" gap={6}>
                          <Icon name="noResults" color="gray.200" size="xl" />
                          <Stack gap={3}>
                            <Text size="md" color="gray.500">
                              該当するブランドが見つかりませんでした
                            </Text>
                            <Text size="sm" color="gray.400">
                              キーワードを変更してみたり、
                              <br />
                              入力ミスがないかをご確認ください。
                            </Text>
                          </Stack>
                        </Stack>
                      </Center>
                    ) : (
                      <Stack overflowY="auto" maxH="240px" px={1}>
                        <RadioGroup
                          display="flex"
                          flexDirection="column"
                          gap={2}
                          value={selectedBrand ? selectedBrand.id : ''}
                          onChange={handleBrandChange}
                        >
                          {filteredBrands.map((brand) => (
                            <Radio key={brand.id} value={brand.id}>
                              {brand.name}
                            </Radio>
                          ))}
                        </RadioGroup>
                      </Stack>
                    )}
                  </Stack>
                </TabPanel>
                <TabPanel pt={3} pb={0}>
                  <Stack gap={3}>
                    <Flex
                      gap={2}
                      alignItems="center"
                      bgColor={'blue.50'}
                      px={3}
                      py={2}
                    >
                      <Text>選択したグループが</Text>
                      <RadioGroup
                        display="flex"
                        gap={2}
                        defaultValue={groupCondition}
                        onChange={(v: PostGroupCondition) => {
                          setSelectedGroupCondition(v);
                          const newGroups = [...selectedGroups];
                          if (v === 'and') {
                            const ids =
                              newGroups.length > 0
                                ? newGroups.reduce((commonIds, item) => {
                                    return commonIds.filter((id) =>
                                      item.storeIds.includes(id),
                                    );
                                  }, newGroups[0].storeIds)
                                : [];
                            setSelectedStoreIds([...ids]);
                          } else {
                            const ids = newGroups.flatMap(
                              (group) => group.storeIds,
                            );
                            setSelectedStoreIds([...new Set(ids)]);
                          }
                        }}
                      >
                        <Radio value="and">すべてを含む</Radio>
                        <Radio value="or">いずれかを含む</Radio>
                      </RadioGroup>
                    </Flex>
                    <Box position="relative" top="0">
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          <Icon name="search" color="blue.500" />
                        </InputLeftElement>
                        <Input
                          placeholder="グループ名で検索"
                          pl={10}
                          onInput={() => setGroupCanSearch(true)}
                          onChange={(e) => {
                            setGroupWord(e.target.value);
                            if (e.target.value.length === 0) {
                              handleGroupSearch('');
                            }
                          }}
                          onCompositionStart={() => setIsComposing(true)}
                          onCompositionEnd={() => setIsComposing(false)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' && !isComposing)
                              handleGroupSearch(groupWord);
                          }}
                        />
                      </InputGroup>
                      {groupCanSearch && groupWord.length > 0 && (
                        <SearchBox
                          word={groupWord}
                          onClickSearchBox={handleGroupSearch}
                        />
                      )}
                    </Box>
                    {isNoGroup ? (
                      <Center flexDirection="column" mt={16}>
                        <Text>まだグループがありません</Text>
                      </Center>
                    ) : (
                      <>
                        {filteredGroups.length === 0 ? (
                          <Center textAlign="center" mt={8}>
                            <Stack align="center" gap={6}>
                              <Icon
                                name="noResults"
                                color="gray.200"
                                size="xl"
                              />
                              <Stack gap={3}>
                                <Text size="md" color="gray.500">
                                  該当するグループが見つかりませんでした
                                </Text>
                                <Text size="sm" color="gray.400">
                                  キーワードを変更してみたり、
                                  <br />
                                  入力ミスがないかをご確認ください。
                                </Text>
                              </Stack>
                            </Stack>
                          </Center>
                        ) : (
                          <Stack overflowY="auto" maxH="240px" px={1}>
                            <CheckboxGroup
                              value={selectedGroups.map((b) => b.id)}
                              onChange={handleGroupChange}
                            >
                              {filteredGroups.map((group) => (
                                <Checkbox key={group.id} value={group.id}>
                                  {group.name}
                                </Checkbox>
                              ))}
                            </CheckboxGroup>
                          </Stack>
                        )}
                      </>
                    )}
                  </Stack>
                </TabPanel>
                <TabPanel pt={3} pb={0}>
                  <Stack gap={3}>
                    <Box position="relative" top="0">
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          <Icon name="search" color="blue.500" />
                        </InputLeftElement>
                        <Input
                          placeholder="店舗名、店舗コード（店番）、郵便番号を含む住所で検索"
                          pl={10}
                          onInput={() => setStoreCanSearch(true)}
                          onChange={(e) => {
                            setStoreWord(e.target.value);
                            if (e.target.value.length === 0) {
                              handleStoreSearch('');
                            }
                          }}
                          onCompositionStart={() => setIsComposing(true)}
                          onCompositionEnd={() => setIsComposing(false)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' && !isComposing)
                              handleStoreSearch(storeWord);
                          }}
                        />
                      </InputGroup>
                      {storeCanSearch && storeWord.length > 0 && (
                        <SearchBox
                          word={storeWord}
                          onClickSearchBox={handleStoreSearch}
                        />
                      )}
                    </Box>
                    {filteredStores.length === 0 ? (
                      <Center textAlign="center" mt={8}>
                        <Stack align="center" gap={6}>
                          <Icon name="noResults" color="gray.200" size="xl" />
                          <Stack gap={3}>
                            <Text size="md" color="gray.500">
                              該当する店舗が見つかりませんでした
                            </Text>
                            <Text size="sm" color="gray.400">
                              キーワードを変更してみたり、
                              <br />
                              入力ミスがないかをご確認ください。
                            </Text>
                          </Stack>
                        </Stack>
                      </Center>
                    ) : (
                      <Stack overflowY="auto" maxH="240px" px={1}>
                        <Checkbox
                          isChecked={filteredStores
                            .map((val) => val.storeId)
                            .sort()
                            .every(
                              (v, i) =>
                                v === selectedStoreIds.slice().sort()[i],
                            )}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            if (isChecked) {
                              setSelectedStoreIds(
                                filteredStores.map((val) => val.storeId),
                              );
                            } else {
                              setSelectedStoreIds([]);
                            }
                            setSelectedBrand(null);
                            setSelectedGroups([]);
                          }}
                          mb={1}
                        >
                          全て
                        </Checkbox>
                        <CheckboxGroup
                          value={selectedStoreIds}
                          onChange={(values) => {
                            setSelectedStoreIds(
                              values.map((val) => String(val)),
                            );
                            setSelectedBrand(null);
                            setSelectedGroups([]);
                          }}
                        >
                          {filteredStores.map((store) => (
                            <Checkbox key={store.storeId} value={store.storeId}>
                              {store.name}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      </Stack>
                    )}
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            {selectedStoreIds.length > 0 && (
              <Text fontWeight="bold" color="black" mr="auto">
                {selectedStoreIds.length} 店舗を選択しています
              </Text>
            )}
            <Button variant="secondary" onClick={handleClose}>
              キャンセル
            </Button>
            <Button onClick={handleSubmit}>決定</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
