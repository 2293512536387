'use client';

import { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Center,
  CheckboxGroup,
  Checkbox,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { TertiaryButton } from '@/components/Button';
import { Icon } from '@/components/Icon';
import { EditConfirmationDialog } from '@/components/Dialog';
import { Group } from '@/lib/api/schema';
import { useAtomValue } from 'jotai';
import { storeAbilitiesAtom } from '@/lib/atoms/abilities';

export function EditGroupDialog(
  props: Readonly<{
    values?: string[] | null;
    options: Group[];
    onOk: (v: string[]) => void;
  }>,
) {
  const { options, values, onOk } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>(values || []);
  const [filteredOptions, setFilteredOptions] = useState<Group[]>(options);
  const isNoOption = !options || options.length === 0;
  const isNoValue = !values || values.length === 0;
  const isDirty =
    JSON.stringify((values || []).sort()) !==
    JSON.stringify(selectedValues.sort());
  const { isStoreInfoEdit } = useAtomValue(storeAbilitiesAtom);

  useEffect(() => {
    if (isOpen) setFilteredOptions(options);
  }, [isOpen, options]);

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    if (value === '') {
      setFilteredOptions(options);
    } else {
      const words = value.split(/[\s　]/);
      setFilteredOptions(() =>
        options.filter((opt) => words.every((word) => opt.name.includes(word))),
      );
    }
  }

  function handleCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
    const { value, checked } = e.target;
    setSelectedValues((vals) =>
      checked ? [...vals, value] : vals.filter((val) => val !== value),
    );
  }

  function handleClose() {
    if (isDirty) {
      setIsConfirmModalOpen(true);
    } else {
      setSelectedValues(values || []);
      onClose();
    }
  }

  function handleOk() {
    onOk(selectedValues);
    onClose();
  }

  return (
    <>
      <TertiaryButton
        label={!isNoValue ? '編集' : `グループを追加`}
        iconName={isNoValue ? 'addCircle' : 'editCircle'}
        onClick={onOpen}
        isDisabled={!isStoreInfoEdit}
      />

      <Modal
        variant="fixedHeight"
        isOpen={isOpen}
        onClose={handleClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>グループ</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack gap={3}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon name="search" color="blue.500" />
                </InputLeftElement>
                <Input
                  type="search"
                  placeholder="グループ名を検索"
                  onChange={handleInputChange}
                />
              </InputGroup>

              {isNoOption && (
                <Center flexDirection="column" h={300}>
                  <Text>まだグループがありません</Text>
                </Center>
              )}

              {!isNoOption && filteredOptions.length === 0 ? (
                <Center textAlign="center" h="300px">
                  <Stack align="center" gap={6}>
                    <Icon name="noResults" color="gray.200" size="xl" />
                    <Stack gap={3}>
                      <Text size="md">
                        該当するグループが見つかりませんでした
                      </Text>
                      <Text size="sm">
                        キーワードを変更してみたり、
                        <br />
                        入力ミスがないかをご確認ください。
                      </Text>
                    </Stack>
                  </Stack>
                </Center>
              ) : (
                <Stack
                  h="300px"
                  as={CheckboxGroup}
                  defaultValue={selectedValues}
                  // onChange={(v) => setSelectedValues(v)}
                >
                  {filteredOptions?.map((el) => (
                    <Checkbox
                      key={el.id}
                      value={el.id}
                      onChange={handleCheckboxChange}
                    >
                      {el.name}
                    </Checkbox>
                  ))}
                </Stack>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="secondary" onClick={handleClose}>
              キャンセル
            </Button>
            <Button onClick={handleOk}>決定</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isConfirmModalOpen && (
        <EditConfirmationDialog
          isValid={true}
          isOpen={isConfirmModalOpen}
          setIsOpen={setIsConfirmModalOpen}
          onClickPrimary={handleOk}
          onClickSecondary={() => {
            setSelectedValues(values || []);
            onClose();
          }}
        />
      )}
    </>
  );
}
