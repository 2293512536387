/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * メディアのソースタイプ
* GBP_TAB (店舗編集 - Googleビジネスプロフィールタブから投稿された写真)
* PHOTO_MANAGER (写真・動画管理画面から投稿された写真)
 */
export type MediaItemSourceType = typeof MediaItemSourceType[keyof typeof MediaItemSourceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaItemSourceType = {
  GBP_TAB: 'GBP_TAB',
  PHOTO_MANAGER: 'PHOTO_MANAGER',
} as const;
