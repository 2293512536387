/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * メディアのフォーマット
* PHOTO (写真)
* VIDEO (動画)
 */
export type MediaFileMediaFormat = typeof MediaFileMediaFormat[keyof typeof MediaFileMediaFormat];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaFileMediaFormat = {
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
} as const;
