'use client';

import { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { RemoveButton, TertiaryButton } from '@/components/Button';
import { DatePicker } from '@/components/DatePicker';
import { EditConfirmationDialog } from '@/components/Dialog';
import { SelectTime } from '@/components/Select';
import {
  Controller,
  FormProvider,
  UseFieldArrayRemove,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { formatDate } from 'date-fns';
import { BusinessDateBulkAddDialog } from './BusinessDateBulkAddDialog';
import { Labels } from '@/assets/i18n/ja';
import { BusinessHour, StoreAdditionalBusinessDay } from '@/lib/api/schema';
import { ErrorMessage } from '@hookform/error-message';
import { validateHours } from '@/utils/validation';
import { useAtomValue } from 'jotai';
import { storeAbilitiesAtom } from '@/lib/atoms/abilities';

type DatesForm = { dates: StoreAdditionalBusinessDay[] };

export function BusinessDateDialog(props: {
  data?: StoreAdditionalBusinessDay[] | null;
  onOk: (val: StoreAdditionalBusinessDay[] | null) => void;
}) {
  const { data, onOk } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const form = useForm<DatesForm>({
    defaultValues: { dates: [] },
    shouldFocusError: false,
    criteriaMode: 'firstError',
    mode: 'onBlur',
  });
  const { control, formState, getValues, reset } = form;
  const { isDirty, isValid, errors } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dates',
    rules: {
      validate: (vals: StoreAdditionalBusinessDay[]) => {
        const dates = vals.map((v) => v.additionalDate);
        const distinct = [...new Set(dates)];
        return dates.length === distinct.length || '日付が重複しています。';
      },
    },
  });
  const { isStoreInfoEdit } = useAtomValue(storeAbilitiesAtom);

  useEffect(() => {
    if (isOpen) reset({ dates: data ?? [] });
  }, [isOpen, reset, data]);

  function handleClose() {
    isDirty ? setIsConfirmationOpen(true) : onClose();
  }

  function handleOk() {
    const retValues = getValues('dates')
      .sort((a, b) => (a.additionalDate > b.additionalDate ? 1 : -1))
      .map(({ additionalDate, businessHourType, businessHours }) => {
        const hours =
          businessHourType !== 'openHours'
            ? null
            : businessHours!.sort((a, b) =>
                a.openTime! > b.closeTime! ? 1 : -1,
              );
        return { additionalDate, businessHourType, businessHours: hours };
      });
    onOk(retValues);
    onClose();
  }

  return (
    <>
      <TertiaryButton
        label={data ? '編集' : Labels.button.addDates}
        iconName={data ? 'editCircle' : 'addCircle'}
        onClick={onOpen}
        isDisabled={!isStoreInfoEdit}
      />

      {isConfirmationOpen && (
        <EditConfirmationDialog
          isValid={isValid}
          isOpen={isConfirmationOpen}
          setIsOpen={setIsConfirmationOpen}
          onClickPrimary={handleOk}
          onClickSecondary={onClose}
        />
      )}

      {isOpen && (
        <Modal
          variant="fixedHeight"
          size="lg"
          isOpen={isOpen}
          onClose={handleClose}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>特別営業時間</ModalHeader>
            <ModalCloseButton />
            <ModalBody as={Flex} direction="column" gap={2} align="flex-start">
              <FormProvider {...form}>
                {fields.map((field, index) => (
                  <DateHourView
                    key={field.id}
                    index={index}
                    removeDate={remove}
                  />
                ))}
              </FormProvider>
              {errors.dates?.root && (
                <Text variant="invalid">{errors.dates?.root?.message}</Text>
              )}
              <TertiaryButton
                label={Labels.button.addDates}
                iconName="addCircle"
                onClick={() => {
                  append({
                    additionalDate: '',
                    businessHourType: 'close',
                    businessHours: null,
                  } as StoreAdditionalBusinessDay);
                }}
              />
              <BusinessDateBulkAddDialog
                onOk={(vals) => vals.map((v) => append(v))}
              />
            </ModalBody>
            <ModalFooter>
              <Button variant="secondary" onClick={handleClose}>
                {Labels.button.cancel}
              </Button>
              <Button isDisabled={!isValid} onClick={handleOk}>
                {Labels.button.done}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

function DateHourView(props: {
  index: number;
  removeDate: UseFieldArrayRemove;
}) {
  const { index, removeDate } = props;
  const { control, formState, trigger } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useFormContext<DatesForm, any, DatesForm>();
  const { errors, isValid } = formState;
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `dates.${index}.businessHours` as const,
    rules: {
      validate: (hours: BusinessHour[], { dates }: DatesForm) => {
        if (
          dates[index]?.businessHourType &&
          dates[index].businessHourType !== 'openHours'
        )
          return true;
        return validateHours(hours);
      },
    },
  });

  console.log('DateHourView', index, 'errors', errors);

  return (
    <Controller
      control={control}
      name={`dates.${index}.businessHourType`}
      render={({ field: { value, onChange } }) => (
        <Flex align="flex-start" gap={4} pb={2}>
          <Controller
            control={control}
            name={`dates.${index}.additionalDate`}
            rules={{ required: '日付を選択して下さい。' }}
            render={({ field: { value: date, onChange: onDateChange } }) => (
              <DatePicker
                monthsShown={2}
                minDate={new Date()}
                selected={date !== '' ? new Date(date) : null}
                onChange={(v) => {
                  if (v) {
                    onDateChange(formatDate(v, 'yyyy-MM-dd'));
                    trigger(`dates`);
                  }
                }}
              />
            )}
          />
          <Switch
            pt={3}
            isChecked={value !== 'close'}
            onChange={(e) => {
              const v = e.target.checked ? 'openHours' : 'close';
              if (v === 'openHours' && fields.length === 0) {
                append({ openTime: null, closeTime: null });
              }
              onChange(v);
            }}
          >
            {value === 'close' ? Labels.dateType.close : Labels.dateType.open}
          </Switch>
          <Flex direction="column" gap={1} align="flex-start">
            {value === 'close' ? (
              <RemoveButton onClick={() => removeDate(index)} />
            ) : (
              <RadioGroup
                defaultValue={value}
                onChange={(v) => {
                  if (v === 'openHours' && fields.length === 0) {
                    append({ openTime: null, closeTime: null });
                  }
                  onChange(v);
                }}
              >
                <Flex align="center" gap={4} pt={2} pb={1}>
                  <Radio value={'openHours'}>{Labels.hourType.openHours}</Radio>
                  <Radio value={'open24h'}>{Labels.hourType.open24h}</Radio>
                </Flex>
              </RadioGroup>
            )}
            {value === 'openHours' &&
              fields.map(({ id, openTime, closeTime }, hourIndex) => {
                return (
                  <Fragment key={id}>
                    <Flex align="center" gap={2} pb={2}>
                      <SelectTime
                        placeholder={Labels.hour.openTime}
                        value={openTime}
                        onChange={(v) =>
                          update(hourIndex, { openTime: v, closeTime })
                        }
                      />
                      {' - '}
                      <SelectTime
                        placeholder={Labels.hour.closeTime}
                        value={closeTime}
                        onChange={(v) =>
                          update(hourIndex, { openTime, closeTime: v })
                        }
                      />
                      <RemoveButton
                        onClick={() => {
                          remove(hourIndex);
                          if (fields.length < 2) onChange('close');
                        }}
                      />
                    </Flex>
                  </Fragment>
                );
              })}
            {value === 'openHours' && !isValid && (
              <ErrorMessage
                errors={errors}
                name={`dates.${index}.businessHours.root` as const}
                render={({ message }) => (
                  <Text variant="invalid">{message}</Text>
                )}
              />
            )}
            {value === 'openHours' && fields.length < 3 && (
              <TertiaryButton
                label={Labels.button.addHours}
                iconName="addCircle"
                onClick={() => append({ openTime: null, closeTime: null })}
              />
            )}
          </Flex>
        </Flex>
      )}
    />
  );
}
