/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  Brands,
  ErrorForbiddenResponse,
  ErrorInternalServerResponse,
  ErrorUnauthenticatedResponse,
  GetBrandsParams
} from '../schema'
import { customInstance } from '../mutator/custom-instance';



  
  /**
 * グローバルフィルターに表示するブランド一覧を取得するAPI。
ログインユーザーが参照できるブランドのみ取得。

ブランド名、会社IDでの検索が可能。未指定の場合は全件取得。

 * @summary ブランド一覧を取得する
 */
export const getBrands = (
    params?: GetBrandsParams,
 ) => {
      return customInstance<Brands>(
      {url: `/v1/brands`, method: 'GET',
        params
    },
      );
    }
  


export const getGetBrandsKey = (params?: GetBrandsParams,) => [`/v1/brands`, ...(params ? [params]: [])] as const;


export type GetBrandsQueryResult = NonNullable<Awaited<ReturnType<typeof getBrands>>>
export type GetBrandsQueryError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse

/**
 * @summary ブランド一覧を取得する
 */
export const useGetBrands = <TError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse>(
  params?: GetBrandsParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getBrands>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetBrandsKey(params) : null);
  const swrFn = () => getBrands(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
