'use client';

import { helpText } from '@/assets/i18n/ja';
import { CheckboxGroup, Checkbox, Stack } from '@chakra-ui/react';
import {
  GbpMediaCategory as GbpMediaCategoryType,
  GeneralError,
  MediaCategoriesResponse,
  MediaCategory,
} from '@/lib/api/schema';

import { DialogErrorMessage } from '@/components/Message';

type Props = {
  googleMediaCategories: MediaCategoriesResponse | undefined;
  error: GeneralError | undefined;
  selectedGbpMediaCategories: MediaCategory[];
  handleChangeCheckbox: (values: (string | number)[]) => void;
};
export function GbpMediaCategory({
  googleMediaCategories,
  error,
  selectedGbpMediaCategories,
  handleChangeCheckbox,
}: Props) {
  if (!googleMediaCategories) return null;

  if (error?.status) {
    return <DialogErrorMessage responseStatus={error?.status} h="300px" />;
  }

  const selectedGbpMediaCategoryIds = selectedGbpMediaCategories.map(
    (category) => category.id.toString(),
  );

  return (
    <Stack gap={2}>
      <CheckboxGroup
        defaultValue={selectedGbpMediaCategoryIds}
        onChange={handleChangeCheckbox}
      >
        {googleMediaCategories.items.map((item) => {
          const categoryInfo =
            helpText.google.assetItems[item.name as GbpMediaCategoryType];

          return (
            <Checkbox key={item.id} value={item.id.toString()}>
              {categoryInfo.title}
            </Checkbox>
          );
        })}
      </CheckboxGroup>
    </Stack>
  );
}
